import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Petition } from '../components/ctas/petition';
export const _frontmatter = {
  "title": "Test Petition",
  "description": "Test page to test the petition component",
  "draft": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Testing petitions`}</h1>

    <div className="flex justify-center items-center">
  <Petition goal={50} listId="546aa2bd-22fb-46bc-ba46-32296eb578cb" cta="Support This" mdxType="Petition" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      